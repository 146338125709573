import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">




<path d="M8816 8498 c-20 -18 -32 -50 -56 -148 -17 -69 -42 -169 -55 -223 -14
-54 -25 -100 -25 -103 0 -12 59 -2 72 14 9 9 29 68 44 131 28 110 30 114 62
128 30 12 37 12 58 -2 30 -19 30 -25 -1 -141 -32 -119 -32 -134 -2 -134 41 0
63 38 91 159 29 120 29 164 -2 187 -21 16 -71 18 -108 4 -42 -16 -50 0 -30 68
9 32 16 64 16 70 0 20 -37 14 -64 -10z"/>
<path d="M6996 8493 c-42 -6 -127 -63 -163 -109 -44 -56 -67 -135 -68 -226 0
-71 2 -80 28 -105 24 -24 36 -28 103 -31 70 -4 78 -7 104 -35 27 -30 28 -31
53 -14 42 27 50 51 27 80 -25 32 -25 32 9 76 37 47 71 157 71 233 0 57 -2 63
-36 94 -19 19 -44 34 -54 34 -10 0 -24 2 -32 3 -7 2 -26 2 -42 0z m63 -110
c20 -23 22 -34 18 -72 -10 -75 -36 -150 -65 -182 -23 -26 -35 -31 -75 -31 -85
-2 -109 46 -81 159 22 89 38 120 77 144 42 26 94 19 126 -18z"/>
<path d="M7303 8359 c-41 -20 -93 -70 -93 -90 0 -18 53 -8 82 16 16 14 37 25
47 25 27 0 54 -24 50 -46 -3 -15 -14 -20 -49 -22 -100 -6 -190 -83 -190 -162
0 -53 85 -79 149 -45 26 13 30 13 41 0 19 -22 57 -18 74 8 8 12 20 49 26 82 7
33 18 85 26 115 19 75 18 87 -12 115 -34 32 -93 33 -151 4z m57 -214 c0 -31
-29 -47 -78 -43 -27 2 -38 8 -41 22 -5 28 26 46 76 46 39 0 43 -2 43 -25z"/>
<path d="M7641 8364 c-55 -23 -101 -77 -101 -117 0 -42 19 -60 87 -82 55 -18
72 -40 49 -63 -23 -23 -84 -8 -92 23 -8 31 -41 33 -72 5 -27 -25 -28 -50 -3
-81 14 -18 31 -23 79 -27 53 -4 67 -1 107 22 29 17 52 41 66 68 29 57 20 83
-39 109 -108 47 -123 62 -78 79 33 12 72 -2 79 -29 7 -28 38 -27 66 3 26 28
21 68 -12 91 -27 19 -92 19 -136 -1z"/>
<path d="M8015 8370 c-11 -5 -35 -6 -53 -3 -67 11 -77 -3 -118 -176 -21 -86
-38 -158 -38 -161 -1 -3 10 -6 25 -8 39 -5 56 24 87 147 16 61 33 116 39 122
15 15 74 14 84 -2 13 -21 11 -50 -12 -141 -11 -46 -23 -93 -26 -105 -5 -20 -1
-23 23 -23 35 0 68 45 79 105 11 69 36 144 51 160 25 24 73 19 86 -9 8 -20 6
-42 -12 -108 -13 -46 -25 -98 -28 -115 -4 -30 -2 -33 20 -33 38 0 56 29 84
131 35 128 36 185 5 210 -30 25 -72 24 -122 -1 l-40 -21 -26 21 c-26 21 -73
25 -108 10z"/>
<path d="M8505 8361 c-39 -17 -95 -72 -95 -92 0 -15 60 -10 66 6 3 8 21 19 39
25 44 14 78 -1 73 -33 -3 -19 -11 -23 -58 -27 -30 -3 -68 -13 -85 -22 -81 -43
-122 -141 -77 -181 24 -21 93 -22 133 -2 26 14 30 14 35 0 9 -24 61 -19 78 8
15 22 66 235 66 273 0 58 -93 83 -175 45z m55 -210 c0 -34 -28 -52 -74 -49
-31 2 -42 8 -45 22 -5 28 26 46 76 46 36 0 43 -3 43 -19z"/>
<path d="M4925 8093 c-447 -39 -767 -120 -1170 -297 -33 -15 -76 -35 -95 -45
-360 -190 -644 -410 -864 -671 -119 -140 -153 -188 -253 -357 -354 -601 -387
-1329 -88 -1959 86 -183 240 -420 359 -552 69 -78 236 -238 306 -295 41 -34
77 -64 80 -67 3 -3 21 -17 40 -30 19 -13 37 -27 40 -31 11 -15 254 -161 351
-211 120 -62 303 -142 434 -188 185 -65 507 -140 660 -154 l60 -6 -85 24
c-124 35 -282 96 -409 160 -112 55 -283 160 -289 177 -2 5 -10 9 -17 9 -7 0
-15 3 -17 8 -1 4 -43 41 -93 82 -168 139 -297 285 -421 475 -124 190 -224 420
-283 651 -48 191 -55 258 -55 524 0 235 2 266 27 391 132 672 548 1229 1142
1527 106 53 154 74 297 127 50 19 213 60 328 82 121 23 560 26 680 5 279 -50
650 -182 712 -253 9 -10 24 -19 35 -19 10 0 45 -18 78 -40 76 -50 94 -52 36
-2 l-44 37 94 5 93 5 24 35 23 35 -113 5 -113 5 2 35 c1 20 7 48 12 63 10 26
12 27 96 27 72 0 88 3 105 20 40 40 27 50 -64 50 -102 0 -111 7 -93 71 l13 44
103 3 c93 2 105 5 122 25 36 43 28 47 -111 47 -175 0 -180 -3 -205 -110 -38
-163 -77 -306 -85 -314 -23 -23 -17 38 28 322 33 209 45 305 38 311 -16 13
-177 66 -301 100 -175 48 -397 89 -574 106 -146 13 -463 18 -576 8z"/>
<path d="M8441 7674 c-29 -37 -27 -54 7 -54 31 0 58 23 64 55 5 33 -45 33 -71
-1z"/>
<path d="M7143 7621 c-13 -11 -23 -24 -23 -29 0 -6 -14 -20 -30 -32 -17 -12
-35 -30 -41 -41 -9 -17 -7 -21 10 -26 26 -7 27 -28 1 -129 -25 -98 -25 -126
-2 -147 29 -27 67 -21 96 14 31 37 33 49 6 49 -31 0 -34 21 -13 112 16 67 24
85 44 95 25 13 59 49 59 63 0 5 -10 10 -22 12 -19 2 -23 9 -23 38 -1 42 -27
51 -62 21z"/>
<path d="M7985 7560 c-16 -4 -38 -11 -47 -15 -11 -4 -18 -3 -18 4 0 17 -42 12
-65 -7 -16 -13 -34 -71 -73 -229 -29 -117 -52 -217 -52 -223 0 -19 46 -10 67
13 11 12 25 41 32 65 12 42 12 42 51 36 55 -9 84 1 131 43 68 61 93 114 93
199 1 61 -2 77 -19 92 -25 24 -61 32 -100 22z m15 -90 c26 -26 26 -75 0 -134
-13 -32 -26 -47 -45 -52 -33 -8 -74 3 -82 23 -10 24 26 162 45 173 27 15 61
11 82 -10z"/>
<path d="M8331 7559 c-14 -5 -43 -7 -66 -4 -52 8 -66 -7 -93 -100 -18 -62 -62
-240 -62 -251 0 -2 10 -4 23 -4 41 0 66 41 94 151 14 57 30 110 36 117 5 6 22
12 36 12 30 0 67 33 73 64 4 23 -9 28 -41 15z"/>
<path d="M8614 7545 c-83 -42 -119 -121 -73 -164 11 -10 40 -24 65 -31 49 -14
69 -32 60 -55 -13 -34 -106 -3 -106 36 0 20 -53 4 -73 -22 -25 -34 -16 -64 26
-90 82 -49 215 5 249 102 15 44 -12 76 -87 103 -60 21 -74 40 -44 59 22 14 66
1 74 -23 11 -34 46 -36 72 -4 30 38 29 50 -6 85 -38 37 -88 39 -157 4z"/>
<path d="M8972 7559 c-101 -40 -189 -192 -163 -280 16 -52 56 -79 118 -79 50
0 117 30 136 61 14 23 1 55 -16 41 -7 -6 -32 -14 -55 -18 -52 -8 -95 15 -90
49 3 21 8 22 91 23 109 1 125 13 134 97 5 53 4 58 -25 86 -32 33 -81 40 -130
20z m52 -88 c26 -28 12 -41 -45 -41 -42 0 -49 3 -49 20 0 39 65 53 94 21z"/>
<path d="M9252 7543 c-114 -78 -113 -163 2 -196 56 -15 81 -41 56 -57 -28 -18
-62 -10 -90 20 -32 35 -46 37 -77 11 -54 -45 -1 -121 83 -121 89 0 184 75 184
145 0 40 -16 54 -89 80 -63 22 -79 50 -37 61 35 8 54 -1 66 -31 12 -31 42 -33
70 -3 49 53 6 118 -80 118 -35 0 -58 -7 -88 -27z"/>
<path d="M6743 7538 c-13 -15 -33 -75 -57 -176 -20 -84 -36 -155 -36 -157 0
-3 10 -5 23 -5 40 0 65 39 87 135 29 129 36 143 75 151 39 7 75 -9 75 -34 0
-9 -11 -62 -25 -117 -31 -126 -31 -135 -2 -135 42 0 57 28 91 165 29 117 30
151 7 174 -24 24 -84 28 -126 9 -19 -9 -28 -9 -37 0 -19 19 -53 14 -75 -10z"/>
<path d="M7380 7545 c-99 -53 -167 -205 -129 -288 25 -56 117 -74 190 -37 61
31 83 99 24 72 -63 -29 -142 -2 -133 45 3 16 15 18 93 21 83 3 91 5 109 29 28
38 31 101 7 141 -20 30 -24 32 -78 31 -32 -1 -69 -7 -83 -14z m74 -71 c28 -27
19 -42 -28 -49 -51 -8 -66 -2 -66 25 0 37 65 53 94 24z"/>
<path d="M7632 7538 c-12 -14 -22 -31 -22 -39 0 -8 -15 -75 -34 -149 -19 -74
-32 -138 -30 -142 10 -16 41 -7 63 16 21 23 33 57 63 186 13 57 24 70 57 70
28 0 63 30 75 64 6 16 2 18 -31 12 -48 -8 -52 -8 -88 0 -24 4 -35 0 -53 -18z"/>
<path d="M8412 7540 c-12 -12 -22 -24 -22 -28 -1 -4 -18 -74 -39 -157 l-38
-150 25 -3 c46 -6 66 30 106 193 20 83 36 153 36 158 0 15 -47 6 -68 -13z"/>
<path d="M7620 6768 c-52 -34 -166 -108 -252 -164 -86 -57 -170 -110 -185
-118 -68 -37 -67 -33 -14 -137 81 -157 167 -385 190 -504 7 -33 18 -87 26
-120 33 -141 44 -419 25 -605 -14 -133 -40 -264 -77 -390 -77 -257 -204 -508
-361 -710 -83 -106 -245 -274 -337 -348 -38 -31 -72 -60 -75 -63 -3 -3 -12 -9
-20 -12 -8 -4 -51 -32 -95 -62 -95 -66 -279 -161 -389 -201 -111 -39 -79 -42
56 -4 177 49 390 130 541 206 37 19 74 34 84 34 9 0 50 -20 92 -44 73 -42 579
-322 726 -401 39 -21 88 -48 110 -60 40 -23 131 -71 325 -173 58 -30 121 -65
140 -76 19 -12 67 -37 105 -56 39 -19 72 -37 75 -40 9 -11 40 -20 40 -12 0 5
-12 37 -26 73 -14 35 -43 114 -65 174 -22 61 -44 121 -50 134 -6 13 -30 81
-55 150 -25 69 -51 142 -59 161 -8 19 -44 116 -81 215 -36 99 -86 230 -109
290 -24 61 -78 200 -120 310 l-77 200 18 33 c9 17 21 32 26 32 4 0 8 4 8 10 0
5 19 40 42 77 50 82 127 242 163 338 99 267 141 522 132 805 -8 254 -55 481
-146 710 -68 171 -200 410 -225 410 -6 0 -54 -28 -106 -62z"/>
<path d="M5270 6645 c-8 -2 -49 -9 -90 -15 -296 -48 -582 -228 -770 -485 -57
-78 -137 -236 -169 -334 -125 -383 -46 -810 209 -1131 75 -95 135 -148 575
-515 138 -115 252 -211 255 -215 3 -3 57 -49 120 -100 63 -52 117 -98 118
-102 9 -20 34 -2 94 70 36 42 74 87 84 98 11 12 37 43 59 70 22 27 54 64 71
84 17 19 100 118 185 220 84 102 161 194 171 205 104 125 154 185 176 209 42
48 117 177 146 251 15 39 34 85 42 102 21 49 52 217 60 318 11 146 -19 332
-78 487 -126 331 -383 588 -713 713 -128 48 -231 67 -385 71 -80 1 -152 1
-160 -1z m350 -354 c128 -34 247 -102 377 -214 74 -65 168 -219 212 -345 69
-201 46 -461 -57 -652 -205 -378 -655 -547 -1057 -395 -182 68 -351 215 -443
385 -130 240 -135 535 -13 780 126 253 343 407 651 464 56 10 257 -4 330 -23z"/>
<path d="M6690 2933 c-32 -12 -50 -37 -50 -69 0 -24 -5 -34 -20 -39 -11 -3
-20 -10 -20 -15 0 -4 9 -10 20 -13 18 -5 20 -14 20 -111 0 -86 3 -106 15 -106
11 0 15 21 17 108 l3 107 28 3 c28 3 33 26 6 28 -8 1 -20 2 -26 3 -7 0 -13 10
-13 22 0 35 19 59 46 59 16 0 24 5 22 13 -5 14 -26 18 -48 10z"/>
<path d="M4530 2865 l0 -66 -22 15 c-74 52 -160 -31 -144 -139 9 -60 44 -95
94 -95 21 0 43 5 50 12 8 8 15 8 27 -2 30 -25 36 3 33 171 -3 147 -5 164 -20
167 -16 3 -18 -5 -18 -63z m-19 -75 c16 -9 19 -22 19 -85 0 -65 -3 -76 -22
-89 -32 -22 -55 -20 -83 9 -33 32 -36 124 -5 155 22 22 60 26 91 10z"/>
<path d="M5990 2755 c0 -149 2 -175 15 -175 13 0 15 26 15 175 0 149 -2 175
-15 175 -13 0 -15 -26 -15 -175z"/>
<path d="M5076 2913 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M5456 2913 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M6495 2911 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
<path d="M3080 2745 l0 -165 81 0 c76 0 82 2 110 29 38 38 40 88 4 123 -25 25
-25 25 -5 43 29 26 26 80 -5 110 -22 22 -32 25 -105 25 l-80 0 0 -165z m168
118 c16 -22 15 -44 -4 -71 -12 -18 -25 -22 -70 -22 l-54 0 0 54 c0 34 5 56 13
60 23 10 102 -4 115 -21z m2 -143 c11 -11 20 -31 20 -45 0 -43 -33 -65 -95
-65 l-55 0 0 65 0 65 55 0 c42 0 60 -5 75 -20z"/>
<path d="M4770 2745 l0 -165 63 0 c114 0 172 58 171 170 -2 110 -54 160 -166
160 l-68 0 0 -165z m159 121 c20 -13 41 -71 41 -113 0 -100 -43 -148 -125
-141 l-40 3 -2 125 c-2 99 1 127 12 136 15 11 90 5 114 -10z"/>
<path d="M6240 2745 l0 -165 95 0 c78 0 95 3 95 15 0 12 -16 15 -80 15 l-80 0
0 150 c0 127 -2 150 -15 150 -13 0 -15 -25 -15 -165z"/>
<path d="M5590 2861 c0 -17 -4 -32 -10 -32 -5 -1 -15 -2 -22 -3 -22 -2 -19
-24 4 -28 22 -3 23 -8 26 -88 3 -123 6 -130 47 -130 40 0 45 11 13 25 -21 9
-23 18 -26 102 -3 89 -2 92 20 95 26 3 27 23 1 23 -12 0 -20 10 -23 29 -7 41
-30 46 -30 7z"/>
<path d="M3423 2820 c-37 -15 -63 -68 -63 -127 0 -44 4 -56 31 -82 25 -25 39
-31 75 -31 51 0 84 14 84 35 0 19 -10 18 -34 0 -39 -29 -90 -15 -115 33 -22
40 -9 51 59 47 82 -4 100 2 100 32 0 74 -68 120 -137 93z m85 -33 c6 -6 14
-25 17 -40 l7 -28 -67 3 c-38 2 -70 8 -72 14 -2 6 6 23 17 37 23 30 79 38 98
14z"/>
<path d="M3595 2821 c-2 -5 14 -58 36 -119 49 -131 49 -176 -2 -194 -16 -6
-28 -15 -26 -21 6 -20 25 -18 51 5 31 27 140 317 125 333 -15 15 -25 -4 -54
-97 -15 -48 -31 -86 -35 -83 -3 2 -20 43 -36 89 -26 79 -46 107 -59 87z"/>
<path d="M3879 2815 c-48 -26 -71 -102 -48 -162 19 -51 48 -73 96 -73 57 0 81
14 99 55 50 121 -44 236 -147 180z m106 -38 c22 -35 28 -81 15 -121 -27 -84
-126 -70 -143 20 -8 40 9 101 31 113 32 19 80 13 97 -12z"/>
<path d="M4117 2823 c-4 -3 -7 -60 -7 -125 0 -108 2 -119 18 -116 14 3 17 17
20 98 3 106 11 120 69 120 44 0 53 -24 53 -136 0 -68 3 -84 15 -84 12 0 15 18
15 100 0 94 -2 103 -25 125 -27 28 -58 31 -95 10 -21 -11 -27 -11 -41 1 -9 8
-19 11 -22 7z"/>
<path d="M5076 2823 c-3 -3 -6 -60 -6 -125 0 -98 3 -118 15 -118 12 0 15 20
15 119 0 109 -6 142 -24 124z"/>
<path d="M5210 2802 c-50 -50 -49 -153 1 -203 21 -21 78 -26 110 -9 17 9 19 7
19 -17 0 -36 -9 -49 -43 -62 -21 -8 -36 -7 -64 4 -44 19 -56 10 -23 -15 31
-24 92 -26 125 -4 36 23 45 65 45 200 0 101 -3 124 -14 124 -8 0 -18 -5 -21
-10 -4 -7 -14 -5 -28 5 -34 24 -75 19 -107 -13z m111 -12 c16 -9 19 -22 19
-85 0 -65 -3 -76 -22 -89 -30 -21 -50 -20 -82 5 -23 18 -26 28 -26 80 0 46 4
63 20 79 22 22 60 26 91 10z"/>
<path d="M5456 2823 c-3 -3 -6 -60 -6 -125 0 -98 3 -118 15 -118 12 0 15 20
15 119 0 109 -6 142 -24 124z"/>
<path d="M5758 2813 c-15 -10 -30 -23 -32 -30 -9 -22 11 -23 34 -3 27 23 82
26 100 6 6 -8 10 -25 8 -38 -3 -20 -10 -24 -55 -28 -66 -6 -93 -28 -93 -75 0
-26 6 -39 26 -51 29 -20 79 -21 100 -3 11 9 18 9 26 1 7 -7 18 -12 26 -12 12
0 14 19 10 101 -4 96 -5 103 -31 125 -33 28 -80 31 -119 7z m110 -147 c3 -33
0 -38 -32 -51 -51 -22 -86 -5 -86 42 0 26 12 34 60 44 50 9 55 7 58 -35z"/>
<path d="M6502 2813 c-6 -10 -9 -61 -7 -113 4 -126 3 -120 20 -120 12 0 15 20
15 119 0 116 -9 151 -28 114z"/>
<path d="M6808 2801 c-29 -25 -32 -35 -36 -95 -4 -64 -3 -67 28 -96 26 -25 40
-30 79 -30 46 1 81 17 81 39 0 15 -11 14 -34 -4 -23 -17 -59 -19 -85 -5 -21
11 -47 67 -37 78 4 4 42 7 84 7 76 0 77 0 80 27 4 36 -23 87 -54 98 -43 17
-72 11 -106 -19z m120 -34 c9 -19 11 -32 4 -39 -12 -12 -124 -6 -129 8 -2 6 6
23 18 38 17 22 28 27 58 24 28 -2 39 -9 49 -31z"/>
<path d="M2597 2614 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
<path d="M2715 2610 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
<path d="M2835 2611 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
<path d="M7030 2605 c-10 -12 -9 -16 3 -21 21 -8 37 3 30 21 -7 19 -17 19 -33
0z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
